import { ListHeaderItem } from '@/components/common/list';

const initData = () => {
    const headers: Array<ListHeaderItem> = [{
        name: 'OrderNumber',
        label: WordList.OrderOrderNumber
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType
    }, {
        name: 'AptNumber',
        label: WordList.ProperAllTextCounts
    }, {
        name: 'TotalPrice',
        label: WordList.OrderTotalPrice
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];
    const typeOptions = [{
        label: WordList.SelectAll,
        name: 'all'
    }, {
        label: WordList.ProperAllTextActivate,
        name: '1'
    }, {
        label: WordList.ProperAllTextRenew,
        name: '4'
    }, {
        label: WordList.AdditionalApp,
        name: '3'
    }];
    const statusOptions = [{
        label: WordList.SelectAll,
        name: 'all'
    }, {
        label: WordList.ProperAllTextProcessing,
        name: '0'
    }, {
        label: WordList.ProperAllTextSuccessed,
        name: '1'
    }, {
        label: WordList.PropertyAllTextFailed,
        name: '2'
    }, {
        label: WordList.ProperAllTextTimeOut,
        name: '3'
    }, {
        label: WordList.TabelFootCancel,
        name: '5'
    }, {
        label: WordList.ProperAllTextSystemProcessing,
        name: '6'
    }];
    return {
        headers,
        typeOptions,
        statusOptions
    };
};

export default null;
export {
    initData
};